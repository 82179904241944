import { Injectable } from '@angular/core'
import { IEpochRange } from '@tradecafe/types/core'
import { ModalService } from 'src/shared/modal'
import { DateRangePickerComponent, DateRangePickerOptions } from './date-range-picker.component'

@Injectable()
export class DateRangePickerService {
  constructor(private readonly modal: ModalService) {}

  showDateRangePicker(options: DateRangePickerOptions) {
    return this.modal.openDialog<DateRangePickerComponent, DateRangePickerOptions, IEpochRange>(DateRangePickerComponent, options).toPromise()
  }
}
