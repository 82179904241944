import { ChangeDetectionStrategy, Component, Inject } from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"
import { IEpochRange } from "@tradecafe/types/core"
import { BehaviorSubject } from "rxjs"
import { map } from "rxjs/operators"


export interface DateRangePickerOptions {
  title: string
  description?: string
  useUtc?: boolean
  dateRange?: IEpochRange
  operation?: (dateRange: IEpochRange) => Promise<void>
  okText?: string
  okIcon?: string
}

@Component({
  selector: 'tc-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangePickerComponent {
  constructor(
    private readonly dialogRef: MatDialogRef<DateRangePickerComponent, IEpochRange>,
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: DateRangePickerOptions,
  ) {}

  // config
  protected readonly title = this.dialogData.title
  protected readonly description = this.dialogData.description || ''
  protected readonly useUtc = this.dialogData.useUtc
  protected readonly operation = this.dialogData.operation
  protected readonly okIcon = this.dialogData.okIcon || 'fa-check'
  protected readonly okText = this.dialogData.okText || 'OK'

  // form
  protected readonly pickerForm = new FormGroup({
    from: new FormControl(this.dialogData.dateRange?.from, Validators.required),
    to: new FormControl(this.dialogData.dateRange?.to, Validators.required),
    tbd: new FormControl(this.dialogData.dateRange?.tbd),
  })

  // state
  protected readonly inProgress$ = new BehaviorSubject(false)
  protected readonly okClass$ = this.inProgress$.pipe(map(inProgress => ({
    [this.okIcon]: !inProgress,
    'fa-spin': inProgress,
    'fa-spinner': inProgress
  })))

  protected async ok() {
    this.pickerForm.markAsTouched()
    if (!this.pickerForm.valid) return

    if (this.operation) {
      this.inProgress$.next(true)
      try {
        await this.operation(this.pickerForm.getRawValue())
        this.dialogRef.close(this.pickerForm.getRawValue())
      } finally {
        this.inProgress$.next(false)
      }
    } else {
      this.dialogRef.close(this.pickerForm.getRawValue())
    }
  }
}
