<h2 mat-dialog-title>
  <i class="fa fa-solid fa-calendar" aria-hidden="true"></i> {{title}}
</h2>
<div mat-dialog-content>
  @if (description) {
    <p>{{description}}</p>
  }
  <tc-epoch-range-field placeholder="Date Range" [useUtc]="!!useUtc"
    [formGroup]="pickerForm" startCtrlName="from" endCtrlName="to" tbdCtrlName="tbd"
  ></tc-epoch-range-field>
</div>
@if (operation) {
  <mat-progress-bar [mode]="(inProgress$|async) ? 'indeterminate' : undefined" ></mat-progress-bar>
}
<div mat-dialog-actions align="end">
  <button mat-button type="button" mat-dialog-close>Cancel</button>
  <button mat-raised-button type="button" color="primary" (click)="ok()">
    <i class="fa" [ngClass]="okClass$ | async" aria-hidden="true"></i>
    {{okText}}
  </button>
</div>
